import $ from "jquery";
import {GooglePlaceDetails, makeAddressAutocomplete} from "./places";
import {reportUnexpectedAjaxError} from "./utils";

$(document).ready(function () {

    // this code applies to the order form pages
    if($('.order-form').length === 0) {
        return;
    }

    const $address = $('#place_autocomplete');
    const addressAutoComplete = makeAddressAutocomplete($address[0]);
    $address.keydown(event => {
        // Prevent the user selecting an address with the enter key from submitting the form
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    });

    const $loginForm = $('#login-form');
    const validator = $loginForm.validate ({
        rules: {
            // company_code:  {
            //     required: true,
            // },
            company: {
                required: true
            },
            site: {
                required: true
            },
            first_name: {
                required: true,
            },
            last_name: {
                required: true,
            },
            email: {
                required: true,
                email: true,
            },
            address1: {
                required: true,
            },
            city: {
                required: true,
            },
            state: {
                required: true,
            },
            zipcode: {
                required: true,
            },
            shoe_type: {
                required: true,
            },
            shoe_size: {
                required: true,
            }
        },
        messages: {
            // company_code: {
            //     required: pgettext('field error', 'Please enter company code')
            // },
            company: {
                required: pgettext('field error', 'Please select your company')
            },
            site: {
                required: pgettext('field error', 'Please select your site')
            },
            first_name: {
                required: pgettext('field error', 'Please enter your first name')
            },
            last_name: {
                required: pgettext('field error', 'Please enter your last name')
            },
            email: {
                required: pgettext('field error', 'Please enter a valid email address')
            },
            address1: {
                required: pgettext('field error', 'Please enter your address')
            },
            city: {
                required: pgettext('field error', 'Please enter your city')
            },
            state: {
                required: pgettext('field error', 'Please enter your state')
            },
            zipcode: {
                required: pgettext('field error', 'Please enter your zip code')
            },
            shoe_type: {
                required: pgettext('field error', 'Please select shoe type')
            },
            shoe_size: {
                required: pgettext('field error', 'Please select shoe size')
            }
        }
    });

    function shownInputsValid() {
        let allValid = true;

        // validator.elements() only returns elements which are visible
        for (const el of validator.elements()) {
            if (!validator.element(el)) {
                allValid = false;
            }
        }

        return allValid;
    }

    $loginForm.submit(event => {
        // Revalidate all shown inputs
        validator.form();
        if (!shownInputsValid()) {
            event.preventDefault();
            return false;
        }

        // All fields are valid, either submit the form or go to the next page

        const $step = $('.form-step-active');
        if($step.hasClass('final-step')) {
            // We are on the last page, allow the submit to go through
            return;
        }

        // We are NOT on the last page; click the next button and block the submission
        event.preventDefault();
        $step.find('.btn-next').click();
        return false;
    });

    addressAutoComplete.addListener('place_changed', e => {
        // Force revalidation of the address field
        const addressValid = validator.element($address);
        if (!addressValid) {
            return;
        }

        const place = new GooglePlaceDetails(addressAutoComplete.getPlace());

        $('#address1').val(place.getStreetAddress()).change();
        $('#city_form').val(place.getCity()).change();
        $('#state_form').val(place.getStateCode()).change();
        $('#zipcode').val(place.getZipCode()).change();
    });

    // Setup company/site search
    const $company = $('#company');
    const $site = $('#site');
    const $siteError = $('#site_ajax_error');
    const companySearchURL = $('#companySearchURL').val();
    const companyInfoURL = $('#companyInfoURL').val();
    const validateSiteURL = $('#siteValidateURL').val();

    $company.select2({
        minimumInputLength: 3,
        ajax: {
            url: companySearchURL,
            dataType: 'json',
            delay: 250,
            processResults: data => {
                data = data.map(item => ({
                    id: item.prefix_code,
                    text: item.name
                }));
                return {results: data};
            },
            data: params => ({search: params.term}),
            cache: true,
        }
    });
    // Move hidden select to before the newly created select2 so the error messages appear on the bottom
    $company.appendTo($company.parent());

    function setSiteOptions(options) {
        const $placeholder = $site.find('option[value=""]');

        // clear old options
        $site.find('option').not($placeholder).remove();
        $placeholder.prop('selected', true);

        if (options === null) {
            $site.prop('disabled', true);
        } else {
            options.forEach(opt => {
                $site.append($(`<option value="${opt.token}">${opt.name}</option>`));
            });
            $site.prop('disabled', false);
        }

        $site.blur(); // Force revalidation
    }

    $company.change(e => {
        $company.blur(); // Force revalidation

        const companyPrefixCode = $company.val();

        if (!companyPrefixCode) {
            setSiteOptions(null);
            return;
        }

        // Load the info for the selected company
        const req = $.ajax({
            method: 'GET',
            url: companyInfoURL.replaceAll('$company$', companyPrefixCode),
            success: function(data) {
                setSiteOptions(data.sites);
                setAvailableInsoleModels(data.insole_models);
            },
            error: function(resp) {
                console.log('error fetching sites', resp);
                reportUnexpectedAjaxError(data, 'loading sites');
                setSiteOptions(null);
                setAvailableInsoleModels([]);
            }
        });
    });

    //Order Form Steps Logic
    const prevBtns = document.querySelectorAll('.btn-prev');
    const nextBtns = document.querySelectorAll('.btn-next');
    const formSteps = document.querySelectorAll('.form-step');

    let formStepsNum = 0;
    nextBtns.forEach((btn) => {
        btn.addEventListener('click', () => {

            // remove all red borders
            $('input.error').removeClass('error');
            $('select.error').removeClass('error');

            if (formStepsNum === 0) {
                // check validity of all fields on this screen
                // const company_code_filled = validator.element('#company_code');
                const company_valid = validator.element($company);
                const site_valid = validator.element($site);
                const address_valid = validator.element('#place_autocomplete');
                const first_name_valid = validator.element('#first_name');
                const last_name_valid = validator.element('#last_name');
                const email_valid = validator.element('#email');

                // if (!company_code_filled) {
                //     $('#company_code').addClass('error');
                // }
                if (!company_valid) {
                    $company.addClass('error');
                }
                if (!site_valid) {
                    $site.addClass('error');
                }
                if (!address_valid) {
                    $address.addClass('error');
                }
                if (!first_name_valid) {
                    $('#first_name').addClass('error');
                }
                if (!last_name_valid) {
                    $('#last_name').addClass('error');
                }
                if (!email_valid) {
                    $('#email').addClass('error');
                }

                // only continue if all fields are valid
                if(shownInputsValid()) {

                    // extra step to validate the company/site on the backend

                    // show progress cursor and disable next button during this step
                    $('body').css('cursor', 'progress');
                    $('.btn-next').addClass('disabled');

                    // remove ajax error message
                    $siteError.hide();
                    // $('#company_code_ajax_error').hide();

                    // do the ajax call
                    $.ajax({
                        url: validateSiteURL,
                        method: 'POST',
                        data: {
                            company: $company.val(),
                            site: $site.val()
                        },
                        success: function (data) {
                            // if it is valid, move to the next step
                            moveToNextStep();
                            return;
                        },
                        error: function (data) {
                            // invalid results return 400 with a message
                            $siteError.text(data.responseJSON.message).show();
                            $site.addClass('error');
                        },
                        complete: function () {
                            // re-enable next button and restore cursor
                            $('body').css('cursor', 'default');
                            $('.btn-next').removeClass('disabled');
                        }
                    });
                }

            }
            if (formStepsNum === 1) {
                // check validity of all fields on this screen
                const address_valid = validator.element('#address1');
                const city_form_valid = validator.element('#city_form');
                const state_valid = validator.element('#state_form');
                const zip_valid = validator.element('#zipcode');

                if (!address_valid) {
                    $('#address1').addClass('error');
                }
                if (!city_form_valid) {
                    $('#city_form').addClass('error');
                }
                if (!state_valid) {
                    $('#state_form').addClass('error');
                }
                if (!zip_valid) {
                    $('#zipcode').addClass('error');
                }

                // continue if all fields are good
                if(address_valid && city_form_valid && state_valid && zip_valid) {
                    moveToNextStep();
                    return;
                }
            }
            if (formStepsNum === 2) {
                // check validity of all fields on this screen
                const shoe_type_valid = validator.element('#shoe_type');
                const shoe_size_valid = validator.element('#shoe_size');

                if (!shoe_type_valid) {
                    $('#shoe_type').addClass('error');
                }
                if (!shoe_size_valid) {
                    $('#shoe_size').addClass('error');
                }

                // continue if all fields are good
                if(shoe_type_valid && shoe_size_valid) {
                    moveToNextStep();
                    return;
                }
            }


        });
    });

    function moveToNextStep() {
        formStepsNum++;
        updateFormSteps();
    }

    prevBtns.forEach((btn) => {
        btn.addEventListener('click', () => {
            formStepsNum--;
            updateFormSteps();
        });
    });

    function updateFormSteps() {
        formSteps.forEach((formStep) => {
            formStep.classList.contains('form-step-active') &&
            formStep.classList.remove('form-step-active');
        });

        const $page = $(formSteps[formStepsNum]);
        $page.addClass('form-step-active');
        // Focus the first input element on the page which is not readonly
        $page.find('input:not([readonly]):first').focus();

        // show / hide different banner on the last step
        if(formStepsNum <= 2) {
            $('.welcome-header').show();
        } else {
            $('.welcome-header').hide();
        }
    }


    // On change of input elements update Step 3 confirmation screen
    $('.user_change').change(function(){
        const changed_field = $(this).attr('name');
        let changed_field_val = $(this).val();
        if (this.tagName === 'SELECT') {
            // Use the display value for select fields
            changed_field_val = $(this).find('option:selected').text().trim();
        }
        $('#summary_' + changed_field).text(changed_field_val);

        // toggle the optional address2 field
        if(changed_field === 'address2') {
            if(changed_field_val) {
                $('#summary_' + changed_field).show();
            } else {
                $('#summary_' + changed_field).hide();
            }
        }
    });

    // Setup insole options
    const $insole_model_section = $('.insole-model-section');
    const $insole_models = $('[name="insole_model"]');
    const $shoe_type = $('#shoe_type');
    const $shoe_size = $('#shoe_size');

    function setAvailableInsoleModels(models) {
        // Hide/disable all models. We will un-hide the relevant ones next
        $insole_models.prop({
            disabled: true,
            checked: false
        }).parent('.opt-container').hide();

        // Now show/enable any available models
        models.forEach(model_name => {
            $insole_models.filter(`[value="${model_name}"]`).prop(
                'disabled', false
            ).parent('.opt-container').show();
        });

        if (models.length > 1) {
            // Only show model selection if more than 1 model is available
            $insole_model_section.show();
        } else {
            // Auto select the first available model and hide the radio buttons
            $insole_model_section.hide();
            $insole_models.filter(':not(:disabled):first').prop('checked', true);
        }

        // trigger change handlers
        $insole_models.first().change();
    }

    function getSelectedInsoleModel() {
        const $selected_model = $insole_models.filter(':checked');
        return $selected_model.length ? $selected_model.val() : null;
    }

    $insole_models.change(e => {
        const insole_model = getSelectedInsoleModel();

        if (!insole_model) {
            // If no model is selected, disabled the type dropdown
            $shoe_type.prop('disabled', true).val('').change();
            return;
        }

        // At this point we know an insole model has been selected

        // Update summary page
        $('#summary_insole_model').text(insole_model);

        // store the selected shoe type before its cleared
        const selected_shoe_type = $shoe_type.val();
        $shoe_type.val('');
        $shoe_type.prop('disabled', false);

        // Hide/disable all options. We will un-hide the relevant ones next
        $shoe_type.find('option').prop({
            hidden: true,
            disabled: true
        });

        // un-hide the type options associated with the selected model
        const available_types = Object.keys(
            SHOE_SIZES_BY_MODEL_AND_TYPE[insole_model] || {}
        );
        available_types.forEach(shoe_type => {
            $shoe_type.find(`option[value="${shoe_type}"]`).prop({
                hidden: false,
                disabled: false,
                selected: selected_shoe_type === shoe_type
            });
        });

        $shoe_type.change();
    });

    $shoe_type.change(function() {
        // get the shoe type
        const insole_model = getSelectedInsoleModel();
        const shoe_type = $(this).val();

        // get the current shoe size
        const selected_shoe_size = $shoe_size.val();

        // clear the selected size
        $shoe_size.val('');

        // Hide/disable all options. We will un-hide the relevant ones next
        $shoe_size.find('option').prop({
            hidden: true,
            disabled: true
        });

        if(!insole_model || !shoe_type) {
            // If no model or shoe type is selected, disable the shoe size drop down
            $shoe_size.addClass('disabled');
            $shoe_size.prop('disabled', true);
        } else {
            // with a model and shoe type selected, enable the shoe size drop down
            $shoe_size.removeClass('disabled');
            $shoe_size.prop('disabled', false);

            // un-hide the size options that go with the model and shoe type
            SHOE_SIZES_BY_MODEL_AND_TYPE[insole_model][shoe_type].forEach(item => {
                $shoe_size.find(`option[value="${item}"]`).prop({
                    hidden: false,
                    disabled: false,
                    selected: item === selected_shoe_size
                });
            });
        }

        $shoe_size.change();
    });

    // Set no available models to start, they will be populated once the user selects a company
    setAvailableInsoleModels([]);

    // on page load make sure all summary fields are updated
    $('.user_change').change();

});
