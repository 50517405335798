import '@popperjs/core';
import 'bootstrap';

import $ from 'jquery';
import './hooks';
import './order_form';
import './marketing_materials_order_form';
import './forgot_password';
import './registration';
import './dashboard';
import './dashboard/employee_list';
import './dashboard/employee_list/index';
import './dashboard/user_list';
import './dashboard/add_user';
import './dashboard/order_history';
import './dashboard/order_submissions/index';
import './dashboard/settings';
import './login';
import 'jquery-validation';

// expose jQuery globally
window.$ = window.jQuery = require('jquery');

// import our scss files so webpack can see it
import '../scss/main.scss';

// include the tabulator bootstrap5 theme
import 'tabulator-tables/src/scss/themes/bootstrap/tabulator_bootstrap5.scss';

